import { SectionProps } from '../../../types'
import { background, border, color, flexbox, layout, padding, space } from 'styled-system'
import { forwardRef } from 'react'
import { theme } from '../../../theme'
import styled from 'styled-components'

const SectionBase = styled.section<SectionProps>`
  background-color: ${theme.colors.primary.white};
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${space}
  ${padding}
  ${background}
`

const Section = forwardRef<HTMLElement, SectionProps>((props: SectionProps, ref) => {
  const { children, ...rest } = props
  return (
    <SectionBase ref={ref} {...rest}>
      {children}
    </SectionBase>
  )
})

Section.displayName = 'Section'

export { Section }
