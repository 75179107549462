import { color, position, variant } from 'styled-system'

import { Box } from '../Box'
import { ToggleArrowProps } from '../../..'
import styled from 'styled-components'

const ToggleArrowBase = styled(Box)<ToggleArrowProps>`
  alignitems: center;
  width: min-content;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translate(100%, -50%);
  ${position}
`

const ToggleArrowSVG = styled.svg<ToggleArrowProps>`
  box-sizing: border-box;
  transition: all 0.1s linear;
  ${variant({
    prop: 'direction',
    variants: {
      up: { transform: 'scale(1, -1)', '&.open': { transform: 'scale(1, 1)' } },
      down: { transform: 'scale(1, 1)', '&.open': { transform: 'scale(1, -1)' } },
      left: {
        transform: 'rotate(90deg)',
        '&.open': { transform: 'rotate(90deg) scale(1, -1)' }
      },
      right: {
        transform: 'rotate(270deg)',
        '&.open': { transform: 'rotate(270deg) scale(1, -1)' }
      }
    }
  })}
  ${color}
`

export const ToggleArrow = ({
  direction = 'down',
  strokeColor,
  open = false,
  ...rest
}: ToggleArrowProps): JSX.Element => (
  <ToggleArrowBase {...rest}>
    <ToggleArrowSVG
      data-testid="toggle-arrow"
      direction={direction}
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      color={strokeColor}
      xmlns="http://www.w3.org/2000/svg"
      className={`toggle-arrow ${open ? 'open' : ''}`}
    >
      <path
        d="M11 1L6 6L1 1"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ToggleArrowSVG>
  </ToggleArrowBase>
)
