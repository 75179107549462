import * as React from 'react'
import { Icon } from '../Icon/index'
import { LayoutProps, layout } from 'styled-system'
import { OptionsProps } from '../../../types/atoms/options.types'
import { Text } from '../Text'
import { theme } from '../../../theme'
import styled, { css } from 'styled-components'

const OptionsContainer = styled.ul<{ maxHeight: string }>`
  border-top: none;
  box-shadow: ${theme.shadows.float};
  border: ${`1px solid ${theme.colors.secondary.border}`};
  position: absolute;
  top: 100%;
  min-height: 50px;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: scroll;
  padding: 0;
  tab-index: 1;
  margin: 0;
  width: inherit;
  overflow-y: scroll;
  z-index: ${theme.zIndex.options};
  ${layout};
`

export const OptionItem = styled.li<{ active?: boolean }>`
  gap: 12px;
  height: 44px !important;
  background-color: ${theme.colors.primary.white};
  padding: 0 17px;
  z-index: ${theme.zIndex.options};
  display: flex;
  align-items: center;
  cursor: pointer;
  :focus,
  :hover {
    background-color: ${theme.colors.secondary.backgroundDark};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${theme.colors.secondary.backgroundDark};
    `}
`

export const Options: React.FC<OptionsProps & LayoutProps> = ({
  options,
  onOptionSelect,
  onOptionEnter,
  disabled = false,
  open,
  disabledOptions,
  onDisabledOptionSelect,
  focused = 0,
  optionsMaxHeight = '300px',
  ...rest
}: OptionsProps & LayoutProps) => {
  const optionRef = React.useRef<HTMLLIElement>(null)
  const optionsListRef = React.useRef<HTMLUListElement>(null)

  React.useEffect(() => {
    if (optionRef?.current && optionsListRef?.current) {
      const optionElement = optionRef?.current

      const listRect = optionsListRef.current.getBoundingClientRect()
      const elementRect = optionElement.getBoundingClientRect()

      if (elementRect.bottom > listRect.bottom) {
        optionsListRef.current.scrollTop = Math.min(
          optionElement.offsetTop +
            optionElement.clientHeight -
            optionsListRef.current.offsetHeight,
          optionsListRef.current.scrollHeight
        )
      } else if (elementRect.top < listRect.top) {
        optionsListRef.current.scrollTop = Math.max(optionElement.offsetTop, 0)
      }
    }
  }, [focused])

  React.useEffect(() => {
    if (open && optionRef?.current) {
      optionRef.current?.scrollIntoView?.({
        block: 'nearest'
      })
    }
  }, [open])

  return (
    (!disabled && open && (
      <OptionsContainer {...rest} maxHeight={optionsMaxHeight} role="listbox" ref={optionsListRef}>
        {options?.map((option, idx) => (
          <OptionItem
            key={`select-${idx}`}
            role="option"
            active={focused === idx}
            tabIndex={1}
            onMouseDown={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
              onOptionSelect && onOptionSelect(e, option, idx)
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.keyCode === 13) {
                onOptionEnter && onOptionEnter(e, option, idx)
              }
            }}
            {...(focused == idx && { ref: optionRef })}
          >
            {option?.icon && typeof option.icon === 'string' ? (
              <Icon src={option.icon} alt={option.label} />
            ) : (
              option.icon
            )}
            <Text>{option.label}</Text>
          </OptionItem>
        ))}
        {disabledOptions?.map((option, idx) => (
          <OptionItem
            key={`select-${idx}`}
            role="option"
            active={focused === idx}
            tabIndex={1}
            onMouseDown={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
              onDisabledOptionSelect && onDisabledOptionSelect(e, option, idx)
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.keyCode === 13) {
                onOptionEnter && onOptionEnter(e, option, idx)
              }
            }}
            {...(focused == idx && { ref: optionRef })}
          >
            {option?.icon && typeof option.icon === 'string' ? (
              <Icon src={option.icon} alt={option.label} />
            ) : (
              option.icon
            )}
            <Text color="secondary.disabled">{option.label}</Text>
          </OptionItem>
        ))}
      </OptionsContainer>
    )) ||
    null
  )
}
